import {
  BitcoinIcon,
  ChevronRightIcon,
  MessageCircleDashedIcon,
  RocketIcon,
} from "lucide-react";
import { Button, buttonVariants } from "../ui/button";
import { showMessages } from "@intercom/messenger-js-sdk";
import { Link } from "react-router-dom";
import { cn } from "@/lib/utils";

function HeroImage() {
  return (
    <svg
      width="100%"
      height="auto"
      viewBox="0 0 1185 458"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_0_1"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="1185"
        height="458"
      >
        <rect
          width="1040"
          height="320"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 0 180.594)"
          fill="url(#paint0_radial_0_1)"
        />
      </mask>
      <g mask="url(#mask0_0_1)">
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 -0.0404539 180.638)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 78.7442 166.746)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 157.529 152.854)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 236.314 138.962)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 315.098 125.07)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 393.883 111.179)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 472.667 97.2868)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 551.452 83.395)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 630.236 69.5031)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 709.021 55.6113)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 787.806 41.7194)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 866.59 27.8275)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 945.375 13.9357)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 39.9595 249.92)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 118.744 236.028)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 197.529 222.136)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 276.314 208.244)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 355.098 194.352)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 433.883 180.461)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 512.667 166.569)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 591.452 152.677)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 670.236 138.785)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 749.021 124.893)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 827.806 111.001)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 906.59 97.1095)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 985.375 83.2177)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 79.9595 319.202)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 158.744 305.31)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 237.529 291.418)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 316.314 277.526)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 395.098 263.635)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 473.883 249.743)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 552.667 235.851)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 631.452 221.959)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 710.236 208.067)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 789.021 194.175)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 867.806 180.283)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 946.59 166.392)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 1025.38 152.5)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 119.96 388.484)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 198.744 374.592)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 277.529 360.7)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 356.314 346.808)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 435.098 332.917)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 513.883 319.025)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 592.667 305.133)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 671.452 291.241)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 750.236 277.349)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 829.021 263.457)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 907.806 249.565)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 986.59 235.674)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
        <rect
          x="0.185601"
          y="0.0865471"
          width="79.75"
          height="79.75"
          transform="matrix(0.984808 -0.173648 0.5 0.866025 1065.38 221.782)"
          stroke="#C2E0FF"
          strokeOpacity="0.24"
          strokeWidth="0.25"
        />
      </g>
      <foreignObject x="250.704" y="198.987" width="727.994" height="173.747">
        <div
          style={{ backdropFilter: "blur(4px)", height: "100%", width: "100%" }}
        ></div>
      </foreignObject>
      <path
        data-figma-bg-blur-radius="8"
        d="M262 212C316.5 247.833 507.7 317.3 636.5 242.5C765.3 167.7 909.833 290.333 966 361"
        stroke="url(#paint1_linear_0_1)"
        strokeOpacity="0.1"
        strokeWidth="12"
      />
      <path
        className="shine-path"
        d="M513.5 319L474 249.5"
        stroke="url(#paint2_linear_0_1)"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <rect
        width="100"
        height="100"
        rx="10"
        transform="matrix(0.984808 -0.173648 0.5 0.866025 551 205.682)"
        fill="url(#paint3_linear_0_1)"
      />
      <rect
        width="100"
        height="100"
        rx="8"
        transform="matrix(0.984808 -0.173648 0.5 0.866025 554.878 197.365)"
        fill="#071129"
      />
      <rect
        width="100"
        height="100"
        rx="8"
        transform="matrix(0.984808 -0.173648 0.5 0.866025 554.878 197.365)"
        fill="url(#paint4_linear_0_1)"
        fillOpacity="0.08"
      />
      <g filter="url(#filter1_i_0_1)">
        <path
          d="M646.593 229.506C646.933 228.777 646.02 227.794 644.946 227.736L630.884 226.971C630.112 226.929 629.357 226.384 629.197 225.755L625.538 211.323C625.242 210.157 623.24 209.64 622.785 210.613L611.646 234.461C611.305 235.191 612.218 236.173 613.292 236.232L627.354 236.997C628.126 237.039 628.882 237.583 629.041 238.213L632.7 252.644C632.996 253.811 634.998 254.328 635.453 253.355L646.593 229.506Z"
          fill="#3B82F6"
        />
      </g>
      <g style={{ mixBlendMode: "overlay" }} filter="url(#filter2_f_0_1)">
        <rect
          x="613.282"
          y="249.66"
          width="45.4249"
          height="13.9015"
          rx="6"
          transform="rotate(-65 613.282 249.66)"
          fill="white"
        />
      </g>
      <g filter="url(#filter3_f_0_1)">
        <rect x="632" y="185" width="64" height="12" rx="6" fill="#3B82F6" />
      </g>
      <path
        d="M823.5 102.5L868 180.5L789 194.5L833.5 271"
        stroke="url(#paint5_linear_0_1)"
        strokeLinecap="round"
      />
      <path
        d="M580.5 257C418 222 795.5 143 681.5 228.5"
        stroke="url(#paint6_linear_0_1)"
        strokeLinecap="round"
      />
      <circle cx="679.5" cy="181.5" r="0.5" fill="#3B82F6" fillOpacity="0.25" />
      <circle cx="642.5" cy="200.5" r="0.5" fill="#3B82F6" fillOpacity="0.25" />
      <circle cx="617.5" cy="174.5" r="0.5" fill="#3B82F6" />
      <circle cx="654.5" cy="158.5" r="0.5" fill="#3B82F6" fillOpacity="0.25" />
      <circle cx="654.5" cy="178.5" r="0.5" fill="#3B82F6" />
      <rect
        width="80"
        height="80"
        rx="8"
        transform="matrix(0.984808 -0.173648 0.5 0.866025 291 192.209)"
        fill="url(#paint7_linear_0_1)"
      />
      <rect
        width="80"
        height="80"
        rx="8"
        transform="matrix(0.984808 -0.173648 0.5 0.866025 294.878 183.892)"
        fill="#071129"
      />
      <rect
        width="80"
        height="80"
        rx="8"
        transform="matrix(0.984808 -0.173648 0.5 0.866025 294.878 183.892)"
        fill="url(#paint8_linear_0_1)"
        fillOpacity="0.08"
      />
      <g filter="url(#filter4_i_0_1)">
        <path
          d="M359.205 198.074L348.163 196.697C347.022 196.559 345.449 196.836 344.663 197.314L337.105 201.971C335.651 202.873 335.245 204.945 336.201 206.6L343.05 218.463C343.735 219.649 345.536 221.058 347.04 221.567L359.816 225.947C362.073 226.727 364.762 226.253 365.785 224.894L371.609 217.235C372.294 216.324 372.294 214.613 371.609 213.427L364.76 201.564C363.821 199.906 361.319 198.347 359.205 198.074ZM356.771 212.542L358.995 216.394C359.342 216.994 359.054 217.593 358.361 217.716C357.668 217.838 356.805 217.441 356.459 216.841L354.235 212.989C352.256 212.822 350.21 211.665 349.272 210.04C348.105 208.019 349.052 206.044 351.385 205.633C353.718 205.222 356.559 206.528 357.726 208.549C358.673 210.189 358.208 211.772 356.771 212.542Z"
          fill="#3B82F6"
        />
      </g>
      <rect
        width="80"
        height="80"
        rx="8"
        transform="matrix(0.987688 -0.156434 0.5 0.866025 862 272.209)"
        fill="url(#paint9_linear_0_1)"
      />
      <rect
        width="80"
        height="80"
        rx="8"
        transform="matrix(0.987688 -0.156434 0.5 0.866025 865.901 264.03)"
        fill="#071129"
      />
      <rect
        width="80"
        height="80"
        rx="8"
        transform="matrix(0.987688 -0.156434 0.5 0.866025 865.901 264.03)"
        fill="url(#paint10_linear_0_1)"
        fillOpacity="0.08"
      />
      <g filter="url(#filter5_i_0_1)">
        <path
          d="M925.536 306.444C925.485 306.452 925.435 306.492 925.385 306.5C921.311 305.603 917.307 303.682 914.029 301.084C914.004 301.04 914.003 300.976 913.978 300.932C916.332 301.137 918.677 301.2 920.955 301.176C922.395 302.973 923.894 304.744 925.536 306.444Z"
          fill="#3B82F6"
        />
        <path
          d="M944.06 296.344C944.089 299.537 942.687 302.346 940.137 304.308C939.695 302.338 939.145 300.368 938.401 298.413C940.338 297.769 942.191 297.042 943.933 296.187C943.95 296.249 944.035 296.3 944.06 296.344Z"
          fill="#3B82F6"
        />
        <path
          d="M936.766 283.456C934.327 283.232 931.931 283.113 929.578 283.132C928.129 281.289 926.521 279.487 924.804 277.75C929.121 278.657 933.344 280.672 936.766 283.456Z"
          fill="#3B82F6"
        />
        <path
          d="M910.391 280.207C910.823 282.099 911.441 283.993 912.159 285.904C910.163 286.542 908.286 287.353 906.477 288.25C906.397 285.033 907.698 282.176 910.197 280.189C910.248 280.181 910.34 280.215 910.391 280.207Z"
          fill="#3B82F6"
        />
        <path
          d="M926.533 283.276C922.416 283.511 918.487 284.133 914.813 285.133C914.068 283.049 913.441 280.947 913.075 278.884C913.04 278.761 912.973 278.675 912.921 278.555C914.086 278.065 915.344 277.673 916.771 277.447C918.182 277.224 919.661 277.182 921.132 277.254C921.217 277.37 921.268 277.458 921.369 277.57C923.255 279.409 924.949 281.326 926.533 283.276Z"
          fill="#3B82F6"
        />
        <path
          d="M919.153 298.749C916.623 298.748 914.068 298.639 911.469 298.327C911.317 298.319 911.225 298.35 911.082 298.356C910.092 297.244 909.202 296.083 908.479 294.832C907.765 293.595 907.27 292.356 906.926 291.157C907.052 291.121 907.153 291.105 907.27 291.054C909.129 290.053 911.115 289.208 913.22 288.473C914.772 291.955 916.761 295.4 919.153 298.749Z"
          fill="#3B82F6"
        />
        <path
          d="M942.061 289.513C942.783 290.764 943.27 291.989 943.623 293.202C943.497 293.238 943.388 293.24 943.27 293.29C941.411 294.292 939.408 295.139 937.32 295.872C935.785 292.387 933.796 288.942 931.387 285.595C933.9 285.599 936.473 285.706 939.071 286.017C939.223 286.026 939.332 286.024 939.466 286.003C940.457 287.116 941.347 288.276 942.061 289.513Z"
          fill="#3B82F6"
        />
        <path
          d="M935.73 299.206C936.483 301.304 937.102 303.392 937.468 305.455C937.503 305.578 937.554 305.666 937.605 305.787C936.457 306.274 935.182 306.668 933.771 306.892C932.344 307.118 930.882 307.157 929.394 307.087C929.31 306.972 929.275 306.881 929.174 306.768C927.304 304.927 925.603 303.028 924.01 301.062C926.068 300.945 928.093 300.769 930.057 300.458C932.022 300.147 933.91 299.703 935.73 299.206Z"
          fill="#3B82F6"
        />
        <path
          d="M934.786 296.709C930.855 297.839 926.616 298.511 922.148 298.711C919.493 295.113 917.347 291.396 915.751 287.63C919.682 286.5 923.921 285.829 928.389 285.628C931.044 289.227 933.19 292.943 934.786 296.709Z"
          fill="#3B82F6"
        />
      </g>
      <path
        d="M394 204C420.5 159.534 523 112.599 611 187.202"
        stroke="url(#paint11_linear_0_1)"
        strokeLinecap="round"
      />
      <path
        d="M686 271C712.5 226.534 816 182.897 904 257.5"
        stroke="url(#paint12_linear_0_1)"
        strokeLinecap="round"
      />
      <circle cx="262.5" cy="101.5" r="0.5" fill="white" />
      <circle cx="954.5" cy="212.5" r="0.5" fill="white" />
      <circle cx="409.5" cy="369.5" r="0.5" fill="white" />
      <circle cx="789.5" cy="14.5" r="0.5" fill="white" />
      <circle cx="577.5" cy="50.5" r="0.5" fill="white" fillOpacity="0.5" />
      <circle cx="861.5" cy="88.5" r="0.5" fill="white" fillOpacity="0.25" />
      <defs>
        <filter
          id="filter1_i_0_1"
          x="611.573"
          y="210.102"
          width="35.0918"
          height="47.7635"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.027451 0 0 0 0 0.0666667 0 0 0 0 0.160784 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_0_1" />
        </filter>
        <filter
          id="filter2_f_0_1"
          x="583.01"
          y="178.539"
          width="92.3413"
          height="106.947"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="16"
            result="effect1_foregroundBlur_0_1"
          />
        </filter>
        <filter
          id="filter3_f_0_1"
          x="568"
          y="121"
          width="192"
          height="140"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="32"
            result="effect1_foregroundBlur_0_1"
          />
        </filter>
        <filter
          id="filter4_i_0_1"
          x="335.683"
          y="196.661"
          width="36.4395"
          height="33.6612"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.027451 0 0 0 0 0.0666667 0 0 0 0 0.160784 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_0_1" />
        </filter>
        <filter
          id="filter5_i_0_1"
          x="906.473"
          y="277.222"
          width="37.5874"
          height="33.8964"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.027451 0 0 0 0 0.0666667 0 0 0 0 0.160784 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_0_1" />
        </filter>
        <radialGradient
          id="paint0_radial_0_1"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(520 160) scale(520 160)"
        >
          <stop offset="0.25" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_0_1"
          x1="942.5"
          y1="327.5"
          x2="259.257"
          y2="236.618"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B82F6" stopOpacity="0" />
          <stop offset="0.5" stopColor="#3B82F6" />
          <stop offset="1" stopColor="#3B82F6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_0_1"
          x1="456"
          y1="250"
          x2="456.5"
          y2="319"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B82F6" />
          <stop offset="1" stopColor="#3B82F6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_0_1"
          x1="1.4455"
          y1="2.15291"
          x2="68.6301"
          y2="117.897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B82F6" />
          <stop offset="0.467449" stopColor="#5994F6" />
          <stop offset="1" stopColor="#3B82F6" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_0_1"
          x1="50"
          y1="0"
          x2="50"
          y2="100"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B82F6" stopOpacity="0" />
          <stop offset="1" stopColor="#3B82F6" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_0_1"
          x1="828.752"
          y1="102.5"
          x2="828.752"
          y2="271.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B82F6" stopOpacity="0" />
          <stop offset="1" stopColor="#3B82F6" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_0_1"
          x1="621.799"
          y1="190.103"
          x2="621.799"
          y2="257.238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B82F6" stopOpacity="0" />
          <stop offset="1" stopColor="#3B82F6" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_0_1"
          x1="1.1564"
          y1="1.72233"
          x2="54.904"
          y2="94.3179"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B82F6" />
          <stop offset="0.467449" stopColor="#5994F6" />
          <stop offset="1" stopColor="#3B82F6" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_0_1"
          x1="40"
          y1="0"
          x2="40"
          y2="80"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B82F6" stopOpacity="0" />
          <stop offset="1" stopColor="#3B82F6" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_0_1"
          x1="1.1564"
          y1="1.72233"
          x2="54.904"
          y2="94.3179"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B82F6" />
          <stop offset="0.467449" stopColor="#5994F6" />
          <stop offset="1" stopColor="#3B82F6" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_0_1"
          x1="40"
          y1="0"
          x2="40"
          y2="80"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B82F6" stopOpacity="0" />
          <stop offset="1" stopColor="#3B82F6" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_0_1"
          x1="611"
          y1="187.5"
          x2="394"
          y2="204"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B82F6" stopOpacity="0" />
          <stop offset="1" stopColor="#3B82F6" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_0_1"
          x1="686"
          y1="271"
          x2="903"
          y2="234.636"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B82F6" stopOpacity="0" />
          <stop offset="1" stopColor="#3B82F6" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default function HeroSection(): JSX.Element {
  const url = import.meta.env.VITE_DASHBOARD_URL;

  return (
    <section className="w-full min-h-screen grid place-items-center pt-[63px] bg-gradient-to-b from-primary/[0.1] to-background">
      <div className="container mx-auto px-6 py-8 lg:py-40">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <div className="flex items-center lg:items-start flex-col gap-6 text-center lg:text-start">
            <div className="flex">
              <div className="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-foreground ring-1 ring-white/20 hover:ring-white/30">
                <span className="font-semibold text-green-600">
                  We&apos;re on TrustPilot
                </span>
                <span className="h-4 w-px bg-white" aria-hidden="true" />
                <a
                  href="https://trustpilot.com/review/thunderproxy.com"
                  className="flex items-center gap-x-1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="absolute inset-0" aria-hidden="true" />
                  Check our reviews
                  <ChevronRightIcon
                    className="-mr-2 h-5 w-5 text-muted-foreground"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </div>
            <h1 className="text-3xl md:text-6xl font-bold leading-tight">
              Unbeatable quality, incredible prices
            </h1>
            <p className="text-lg lg:text-xl opacity-90 max-w-lg">
              Lightning-Fast Web Data Collection Without Hassles
            </p>
            <div className="flex flex-col md:flex-row gap-4">
              <Link
                to={`${url}/auth/register`}
                className={cn(buttonVariants({ size: "lg" }))}
              >
                <RocketIcon className="size-4 mr-2" />
                Get started
              </Link>
              <Button
                onClick={() => showMessages()}
                size="lg"
                variant="secondary"
              >
                <MessageCircleDashedIcon className="size-4 mr-2" />
                Talk to an expert
              </Button>
            </div>
            <div className="flex gap-1 items-center text-xs text-muted-foreground">
              <BitcoinIcon className="size-4" />
              Pay with crypto & cards
            </div>
          </div>
          <div className="hidden lg:block">
            <HeroImage />
          </div>
        </div>
      </div>
    </section>
  );
}
